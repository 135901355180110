import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import SEO from '../components/SEO'

import Container from '../styles/Container'

const Title = styled.h1`
  margin-top: 67px;
  margin-bottom: 120px;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 44px;
  line-height: 47px;
  letter-spacing: 0.25px;
  color: #0859ff;
  @media (min-width: 680px) {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 1.67px;
  }
  @media (min-width: 1200px) {
    margin-top: 160px;
    margin-bottom: 160px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
  }
`

const OuterContainer = styled.div`
  background-color: #fdfbf9;
`

const Text = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
  @media (min-width: 1200px) {
    margin-top: 90px;
    margin-bottom: 100px;
  }
  strong {
    font-weight: 700;
  }
  p {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 100;
    color: #002c5e;
    @media (min-width: 680px) {
      letter-spacing: 0.39px;
    }
    @media (min-width: 1200px) {
      margin-bottom: 26px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }
  }
  h1 {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    font-weight: 700;
    color: #002c5e;
    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.25px;
    }
  }
  a {
    font-weight: 700;
  }
`
const text = [{"type":"heading1","text":"Last updated on the 18th of May 2020","spans":[{"start":0,"end":36,"type":"strong"}]},
  {"type":"paragraph","text":"1. Your Agreement with Oros Education Ltd","spans":[{"start":0,"end":41,"type":"strong"}]},
  {"type":"paragraph","text":"1.1 These Terms and Conditions (\"Terms\", \"Terms and Conditions\") govern your relationship with oros-education.com website and platform (the \"Service\") operated by Oros Education Ltd (\"us\", \"we\", or “our”). These terms and conditions set out the conditions under which we grant the Tutor (“you”) a licence to use Oros Education Ltd’s Service which is dedicated to providing a virtual online platform by which a Tutor can receive and manage tutoring orders and deliver tutoring services directly to registered users. Please read these Terms and Conditions carefully before using the Service.","spans":[]},
  {"type":"paragraph","text":"1.2 When you sign up and and make use of Oros Education Ltd’s Service, you agree that the following terms apply to that use in addition to any other terms and conditions which may apply including the Privacy Policy and Cookie Policy. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all tutors, users and others who access or use the Service.","spans":[]},
  {"type":"paragraph","text":"2. Registering a Tutor Account","spans":[{"start":0,"end":30,"type":"strong"}]},
  {"type":"paragraph","text":"2.1 When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.","spans":[]},
  {"type":"paragraph","text":"2.2. In order to be eligible for an account on our Service, Oros Education Ltd currently accepts tutor registration via invite only. Receipt of an invitation link will enable you to register a tutor account, upon agreeing to these Terms in full.","spans":[]},
  {"type":"paragraph","text":"2.3 To register a tutor account, you must provide your email address (which will be your username) and a password. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. You will keep your password relevant to the Service confidential and not share it with anyone else, as you are solely responsible for all activities undertaken on the Service under your username. We are unable to check the identities of all users of the site at any given moment, so if you are aware that your account has been compromised you must contact us at support@oros-education.com immediately.","spans":[]},
  {"type":"paragraph","text":"2.4 Tutors must also provide a verified phone number which is used to distribute important notifications related to class scheduling and student booking requests. Both phone number and email address are important points of contact between you and the Service. If you make any changes to either your email or phone number, you must update your details accordingly or risk termination of use of the Service.All notifications including (but not limited) class booking requests, rescheduling and cancellation, class starting time alert and chat summary will be notified by SMS texts. Please provide the right phone number when registering. If you don't want to get alerted by receiving texts from Oros Education, please inform us by sending an email to support@oros-education.com, we will stop the text notifications for you.","spans":[{"start":749,"end":773,"type":"hyperlink","data":{"link_type":"Web","url":"mailto:support@oros-education.com"}}]},
  {"type":"paragraph","text":"3. Obligations as a Tutor","spans":[{"start":0,"end":25,"type":"strong"}]},
  {"type":"paragraph","text":"3.1 When you make use of the Service and when your provide tuition services to your students, you must comply with any operational requirements as directed by Oros Education Ltd and as amended from time to time and in accordance with these Terms. Furthermore, you specifically agree to:","spans":[]},
  {"type":"paragraph","text":"a. Assume all responsibility in your role as tutor and mentor for the provision of services to your student, which includes the quality, preparation and content of your online classes.","spans":[]},
  {"type":"paragraph","text":"b. Upon request, provide Oros Education Ltd with current account details which must be a real bank account held in your name only. This is used to disburse payment owed for the provision of high-quality tutoring services.","spans":[]},
  {"type":"paragraph","text":"c. Keep your information up to date. You must provide us with information which is accurate and current. This includes but is not limited to your academic background, work experience, ‘About Me’ section, and Available Time.","spans":[]},
  {"type":"paragraph","text":"d. Accept and manage class booking requests made by students via the Service which you will be notified about via your registered email and phone number within a reasonable time frame. You acknowledge that Oros Education Ltd cannot be held responsible for any costs or time incurred before notification has been given to you.","spans":[]},
  {"type":"paragraph","text":"e. Notify Oros Education Ltd immediately if you should become incapacitated and unable to provide previously agreed upon tuition services. Oros Education shall notify the client if you have not already done so, and Oros Education Ltd will discuss in good faith with you the possibility of postponement of tutoring or reallocation to another tutor.","spans":[]},
  {"type":"paragraph","text":"f. Act at all times in accordance with the highest professional standards.","spans":[]},
  {"type":"paragraph","text":"g. Act in strict accordance with the terms and protocol laid out in the Rescheduling, Cancellation and No-Show Policy of these Terms.","spans":[]},
  {"type":"paragraph","text":"h. Be solely responsible for the political content of your utterances and of your tutoring materials. Do not act in any way which could be perceived to be a defamation of the government of the People’s Republic of China.","spans":[]},
  {"type":"paragraph","text":"3.2 Failure to comply with any of the above may result in termination without warning of use of the Service.","spans":[]},
  {"type":"paragraph","text":"4. Rescheduling, Cancellation and No-Show Policy","spans":[{"start":0,"end":48,"type":"strong"}]},
  {"type":"paragraph","text":"4.1 Arrangements between tutor and client can be rearranged or cancelled by either party in advance of the online lesson taking place.","spans":[]},
  {"type":"paragraph","text":"4.2 Tutors are able to cancel or reschedule their classes by entering the My Classes page of the tutor’s user dashboard and selecting either the Reschedule or Cancel functions. Oros Education offers a 24 hour advance notice policy, meaning the following:","spans":[]},
  {"type":"paragraph","text":"a. If you, the tutor, cancels in excess of 24 hours of the online lesson’s scheduled start time, there will be no immediate penalty to you, save in the case of over-frequent cancellation (see below), and the client shall be offered either:","spans":[]},
  {"type":"paragraph","text":"A rescheduled online lesson, the rescheduling arrangements thereof you shall notified of via email and SMS, or failing agreement on that,","spans":[]},
  {"type":"paragraph","text":"A substitute tutor to cover the class in question, or failing agreement on either of these two solutions,","spans":[]},
  {"type":"paragraph","text":"The client will be credited the full amount of the fees associated with the class in question, and the relevant future payment shall be removed from your Pending Balance.","spans":[]},
  {"type":"paragraph","text":"b. If you reschedule in excess of 24 hours of the online lesson’s scheduled start time, the client will be notified of your request via SMS, and they will be able to select among the new time-slots suggested by you a new time which is suitable for them. If the client does not accept any of your suggested times, they have the ability to suggest their own new time-slots. You will be notified of these via email and SMS, and will be able to make your selection of a new time-slot by returning to the My Classes page. If both parties are unable to reach a consensus by 24 hours in advance of the class, but the rescheduling request has already been submitted by either party, the class will be considered cancelled at no extra penalty to either party, and the Service will then follow the steps laid out in 4.2.a(ii) and 4.2.a(iii).","spans":[]},
  {"type":"paragraph","text":"4.3 There are penalties for tutors who cancel or reschedule within 24 hours of the start date and time of a scheduled class. This is to discourage improper cancellation by our tutors and to ensure quality of our Service. It is a consideration of the fact that clients are often students who are preparing for important exams or who have urgent academic requirements.","spans":[]},
  {"type":"paragraph","text":"4.4 If you cancel or submit a reschedule request within 24 hours of the scheduled start date and time of the class in question, you will not only immediately forfeit the payment of fees for the class in question, but will forfeit half an hour of future fees, equivalent to the value of the class in question. The fees will be offset against your Pending Balance as laid out in 5.4, and the client will be credited with compensation equivalent to the value of the forfeited time in question.","spans":[]},
  {"type":"paragraph","text":"4.5 Please note that although Oros Education Ltd operates a 24 hour advance notice policy in regards to rescheduling and cancellation, the Service reserves the right to monitor frequency of lesson cancellation and rescheduling. In order to improve our Service for clients, in cases of over-frequent cancellation or rescheduling, Oros Education Ltd may in its sole discretion contact you directly to issue a warning, or may directly terminate your access to the Service without prior notice.","spans":[]},
  {"type":"paragraph","text":"4.6 A client is able to reschedule or cancel with no penalty by giving advance notice in excess of 24 hours from the scheduled class start date and time.","spans":[]},
  {"type":"paragraph","text":"4.7 If a client cancels or reschedules within 24 hours in advance of the scheduled class start time, the tutor will be compensated 50% of their hourly wage.","spans":[]},
  {"type":"paragraph","text":"4.8 A ‘no-show’ is defined as the tutor arriving 15 minutes late to a scheduled online lesson, or the client (student) arriving 30 minutes late.","spans":[]},
  {"type":"paragraph","text":"If you arrive over 15 minutes late, the lesson will be considered automatically cancelled at short notice, and will the Service will follow the protocol for penalty laid out in 4.4.","spans":[]},
  {"type":"paragraph","text":"If the client arrives over 30 minutes late, the lesson will be considered automatically cancelled at short notice, and the tutor will be compensated according to the protocol laid out in 4.7.","spans":[]},
  {"type":"paragraph","text":"4.9 The protocol laid out in 4.8 means that tutors are obliged to wait for at least 30 minutes before considering a lesson cancelled, and leaving the online classroom. Clients are given greater leeway in regards to the late-arrival than the tutor due to the fact that they are the paying party, and that many student organise classes to start directly after school. Sometimes they are held back by offline teachers, or encounter transport problems, so expect the tutor to be understanding and cooperative in these cases.","spans":[]},
  {"type":"paragraph","text":"5. Settlement","spans":[{"start":0,"end":13,"type":"strong"}]},
  {"type":"paragraph","text":"5.1 Oros Education Ltd shall pay the Tutor an amount equal to the gross amount collected by Oros Education Ltd, less any commission and charges, for the provision of tutoring services made in connection with this agreement. Such payments shall be made on a fortnightly basis.","spans":[]},
  {"type":"paragraph","text":"5.2 The amount to be disbursed shall be based on the funds present in the Tutor’s Available Balance. Upon completion of a class, the relevant funds will first appear in the Tutor’s Pending Balance. The money will remain in the tutor’s Pending Balance for a period of one week before being made available in the tutor’s Available Balance. This is in order to provide quality control, and in rare cases of dispute, clients will have a period of one week to contact Oros Education Ltd and redress any grievances.","spans":[]},
  {"type":"paragraph","text":"5.3 In response to any dispute, should such an event occur, Oros Education Ltd will be entitled in its sole discretion to refund the client on behalf of the the tutor in full or part. In such cases, funds will be extracted directly from the tutor’s Pending Balance, and the tutor will receive either no funds or only part of the funds.","spans":[]},
  {"type":"paragraph","text":"5.4 If any refund event occurs subsequent to the tutor already receiving the funds, or if the tutor is responsible for late cancellation or misconduct as outlined in the Rescheduling, Cancellation and No-Show Policy of these Terms, Oros Education Ltd shall be entitled in its sole discretion to withhold sufficient monies and offset any amounts payable to the tutor under this agreement from any gross tutor fees collected, present or future, in order to enable Oros Education Ltd thereafter to refund the client on the tutor’s behalf.","spans":[]},
  {"type":"paragraph","text":"5.5 Oros Education Ltd shall be entitled to repay such monies to the client at its discretion. The tutor shall be responsible for all chargebacks and refunds for online lessons or any dispute arising thereby, and shall indemnify Oros Education Ltd against all losses resulting from chargebacks or refund events.","spans":[]},
  {"type":"paragraph","text":"5.6 You confirm that you are self-employed under these Terms. You are directly responsible to your local tax authority (HMRC for UK residents, the IRS for US residents) for all matters regarding Income Tax, VAT and (for UK residents) relevant National Insurance contributions. It is therefore necessary for you to keep records of payments made to you by Oros Education Ltd for completion of annual Tax Returns. You hereby agree to fully indemnify Oros Education Ltd for or against all and any demands by your local tax authority to Oros Education Ltd for Income Tax, VAT or National Insurance, or any other claim, including any interest or penalties, arising out of your failure to account properly or at all for any liabilities to your relevant tax authority arising from payments for delivery your self-employed tutoring services. This indemnity shall include all expenses and claims (inclusive of legal fees) incurred by Oros Education Ltd in dealing with any such claim.","spans":[]},
  {"type":"paragraph","text":"6. Intellectual Property","spans":[{"start":0,"end":24,"type":"strong"}]},
  {"type":"paragraph","text":"6.1 The Service and its original content (excluding content provided by tutors), features and functionality are and will remain the exclusive property of Oros Education Ltd and its licensors. The Service is protected by copyright, trademark, and other laws of both the United Kingdom and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Oros Education Ltd.","spans":[]},
  {"type":"paragraph","text":"6.2 Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material. You are responsible for the content that you post to the Service, including its legality, reliability, and appropriateness.","spans":[]},
  {"type":"paragraph","text":"6.3 When using the Service to deliver online tutorials, if you publish any user-generated content (\"UGC\") by way of delivering online lessons, uploading images, posting content to the online lesson space etc you automatically grant:","spans":[]},
  {"type":"paragraph","text":"to Oros Education Ltd, a world-wide, non-exclusive, royalty-free, perpetual, transferable license (with right to sub-license) to use, record, publish, distribute, prepare derivative works of, display and perform all and any part of your UGC, and the intellectual property rights therein, in connection with the provision of your tutoring services though the Service. This includes without limitation the reproduction and sale of UGC, any part of it, and products containing the same for use by any person anywhere in the world including for use in the promotion and redistribution of part or all of the Service (and derivative works thereof) in any media format and through any media channel.","spans":[]},
  {"type":"paragraph","text":"to each user of the Service whether registered as a tutor, student, or Oros Education Ltd’s partner companies, a worldwide, non-exclusive, perpetual, royalty-free license to access Your UGC through the Services, and to use, reproduce, distribute, prepare derivative works of, display and perform such UGC to the extent permitted by the functionality of the Service and under the terms of this Agreement.","spans":[]},
  {"type":"paragraph","text":"6.4 You acknowledge that the UGC uploaded by you to create your tutor profile shall be viewable by all users of the Service and be accessible via the worldwide web search engines.","spans":[]},
  {"type":"paragraph","text":"6.5 You acknowledge any online classes that you deliver may be recorded and you agree to the storage and use of such recordings by Oros Education Ltd. You hereby agree to waive any and all moral rights in and to all UGC. ","spans":[]},
  {"type":"paragraph","text":"6.6 You furthermore acknowledge that any UGC you use in the delivering of your online classes will not contain any third party copyright material, or material that is subject to other third party proprietary rights unless you have a formal agreement from the rightful owner, or are otherwise legally entitled to use the materials in question in the performance of your classes. You therefore represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.","spans":[]},
  {"type":"paragraph","text":"7. Links To Other Web Sites","spans":[{"start":0,"end":27,"type":"strong"}]},
  {"type":"paragraph","text":"7.1 Our Service may contain links to third-party web sites or services that are not owned or controlled by Oros Education Ltd.","spans":[]},
  {"type":"paragraph","text":"7.2 Oros Education Ltd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Oros Education Ltd shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.","spans":[]},
  {"type":"paragraph","text":"7.3 We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.","spans":[]},
  {"type":"paragraph","text":"8. Termination","spans":[{"start":0,"end":14,"type":"strong"}]},
  {"type":"paragraph","text":"8.1 We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.","spans":[]},
  {"type":"paragraph","text":"8.2 Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.","spans":[]},
  {"type":"paragraph","text":"9. Limitation Of Liability","spans":[{"start":0,"end":26,"type":"strong"}]},
  {"type":"paragraph","text":"9.1 In no event shall Oros Education Ltd, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorised access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.","spans":[]},
  {"type":"paragraph","text":"10. Disclaimer","spans":[{"start":0,"end":14,"type":"strong"}]},
  {"type":"paragraph","text":"10.1 Your use of the Service is at your sole risk. The Service is provided on an \"AS IS\" and \"AS AVAILABLE\" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.","spans":[]},
  {"type":"paragraph","text":"10.2 Oros Education Ltd its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.","spans":[]},
  {"type":"paragraph","text":"11. Governing Law","spans":[{"start":0,"end":17,"type":"strong"}]},
  {"type":"paragraph","text":"11.1 These Terms shall be governed and construed in accordance with the laws of United Kingdom, without regard to its conflict of law provisions.","spans":[]},
  {"type":"paragraph","text":"11.2 Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.","spans":[]},
  {"type":"paragraph","text":"12. Changes","spans":[{"start":0,"end":11,"type":"strong"}]},
  {"type":"paragraph","text":"12.1 We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide sufficient notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.","spans":[]},
  {"type":"paragraph","text":"12.2 By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.","spans":[]},
  {"type":"paragraph","text":"Contact Us","spans":[{"start":0,"end":10,"type":"strong"}]},
  {"type":"paragraph","text":"If you have any questions about these Terms, please contact us at support@oros-education.com.","spans":[{"start":0,"end":93,"type":"strong"}]}];
const TNC = () => {
  return (
    <>
      <SEO title={`Tutor Terms of Service`} description={``} images={``} />
      <Container>{<Title>{`Tutor Terms of Service`}</Title>}</Container>
      <OuterContainer>
        <Container>
          <Text>{RichText.render(text)}</Text>
        </Container>
      </OuterContainer>
    </>
  )
}

export default TNC

